/* eslint-disable react/prop-types */
import { useState } from 'react'
import logo from '../../assets/logo_white.svg'
import './Header.scss'
import { BASE_URL } from '../../utils/consts'

function Header({ scrollToBottom }) {
  const [isDropdownActive, setIsDropdownActive] = useState(false)
  return (
    <header className={isDropdownActive && 'active'}>
      <img src={logo} alt="Logo" onClick={() => setIsDropdownActive(!isDropdownActive)} />
      <ul className={`links ${isDropdownActive && 'active'}`}>
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <a href="/about-us">About Us</a>
        </li>
        <li>
          <div
            onClick={() => {
              scrollToBottom()
              setIsDropdownActive(false)
            }}
          >
            Contact
          </div>
        </li>
        <li>
          <a href={BASE_URL} target="_blank" rel="noreferrer">
            Book now
          </a>
        </li>
      </ul>
    </header>
  )
}

export default Header
