import './Footer.scss'
import logoImg from '../../assets/logo_white.svg'
import footerImg from '../../assets/footer.png'

const Footer = () => (
  <footer>
    <div className="contact-info">
      <h2 className="title">Get in touch</h2>
      <div className="contact">
        <h2 className="type">mailing address</h2>
        <a href="https://goo.gl/maps/uSofnTQtPBgfSqD97" className="content">
          Ferhadija 16
        </a>
      </div>
      <div className="contact">
        <h2 className="type">email address</h2>
        <a href="mailto:info@theplace87.com" className="content">
          info@theplace87.com
        </a>
      </div>
      <div className="contact">
        <h2 className="type">phone number</h2>
        <a href="tel:+38762014555" className="content">
          +387 62 014 555
        </a>
      </div>
    </div>
    <img src={footerImg} className="illustration" />
    <div className="decoration">
      <img src={logoImg} />
      <div className="line"></div>
    </div>
  </footer>
)

export default Footer
