export const formatDate = (date) => {
  const dateObj = new Date(date)
  const [y, m, d] = [
    dateObj.toLocaleDateString('default', { year: 'numeric' }),
    dateObj.toLocaleDateString('default', { month: '2-digit' }),
    dateObj.toLocaleDateString('default', { day: '2-digit' }),
  ]
  return `${y}-${m}-${d}`
}

export const getTomorrow = (date) => {
  const dateObj = new Date(date)
  const tomorrow = dateObj.getDate() + 1
  return dateObj.setDate(tomorrow)
}
