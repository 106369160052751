import PropTypes from 'prop-types'
import './Button.scss'

function Button({ text, link }) {
  return (
    <div className="btn">
      <a href={link}>
        <h3>{text}</h3>
      </a>
    </div>
  )
}

Button.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
}

export default Button
