/* eslint-disable react/prop-types */
import { useRef } from 'react'
import Footer from './footer/Footer'
import Header from './header/Header'

function Layout({ children }) {
  const footerRef = useRef(null)
  const scrollToBottom = () => {
    footerRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  return (
    <main>
      <Header scrollToBottom={scrollToBottom} />
      {children}
      <div ref={footerRef} />
      <Footer />
    </main>
  )
}

export default Layout
