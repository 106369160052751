import PropTypes from 'prop-types'
import './Facility.scss'

function Facility({ title, description, icon }) {
  return (
    <div className="container">
      <h2 className="title">{title}</h2>
      <div className="content">
        <p className="description">{description}</p>
        <img src={icon} />
      </div>
    </div>
  )
}

Facility.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.any,
}

export default Facility
