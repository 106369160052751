import deluxe1 from '../assets/rooms/deluxe_1.png'
import deluxe2 from '../assets/rooms/deluxe_2.jpg'
import deluxe3 from '../assets/rooms/deluxe_3.png'
import deluxe4 from '../assets/rooms/deluxe_4.png'
import deluxe5 from '../assets/rooms/deluxe_5.png'
import deluxe6 from '../assets/rooms/deluxe_6.png'

export const CUSTOMER_REVIEWS = [
  {
    name: 'Iris Beus',
    comment:
      "We had an incredible stay! The location couldn't have been more ideal, right in the heart of town with everything we needed at our doorstep. Our room was impeccably clean and incredibly comfortable.",
    review: 5,
  },
  {
    name: 'Ryan Hughes',
    comment:
      'Our stay was fantastic! The fast and reliable WiFi kept us connected seamlessly, and the rooms were exceptionally clean. We opted for the deluxe room with an breathtaking view. Highly recommended!',
    review: 5,
  },
  {
    name: 'Anthony Garrett',
    comment: 'Great stay! Family friendly and spotlessly clean rooms. We took the family room with balcony.',
    review: 5,
  },
]

export const ROOMS = [
  {
    name: 'Deluxe Room I',
    description:
      "Elevate your stay to new heights in our lavish Deluxe Room I. Here, panoramic vistas and sumptuous interiors combine to create an atmosphere of sheer grandeur. It's the largest of our rooms, making it an ideal choice for families seeking the ultimate retreat.",
    image: deluxe1,
    secondaryText:
      'The twin/double room provides a wardrobe, a safe deposit box, as well as a private bathroom boasting a shower and a hairdryer. The spacious air-conditioned twin/double room features a flat-screen TV, soundproof walls, a coffee machine, a seating area as well as mountain views.',
  },
  {
    name: 'Deluxe Room II',
    description:
      'Ideal for couples seeking a harmonious blend of comfort and sophistication, Deluxe Room II welcomes you with its one-bedroom charm. Experience a tranquil haven where relaxation and elegance intertwine seamlessly.',
    image: deluxe2,
    secondaryText:
      'The twin/double room features a wardrobe, a safe deposit box, as well as a private bathroom featuring a shower and a hairdryer. The air-conditioned twin/double room provides a flat-screen TV, soundproof walls, a coffee machine, a seating area as well as mountain views.',
  },
  {
    name: 'Deluxe Room III',
    description:
      'Step into the lap of opulence with our premium suites, designed to satisfy even the most discerning tastes. Not only can you luxuriate in this room, but it can also transform to accommodate 2, 3, or 4 guests, ensuring a truly lavish experience.',
    image: deluxe3,
    secondaryText:
      'The twin/double room features a wardrobe, a safe deposit box, as well as a private bathroom featuring a shower and a hairdryer. The air-conditioned twin/double room offers a flat-screen TV, soundproof walls, a coffee machine, a seating area as well as mountain views.',
  },
  {
    name: 'Deluxe Room IV',
    description:
      "Whether you're embarking on a solo adventure or enjoying a romantic getaway for two, Deluxe Room I is your perfect match. Gaze upon the majestic view from your window while basking in the warm embrace of a room illuminated by soft, inviting lights.",
    image: deluxe4,
    secondaryText:
      'The twin/double room provides a wardrobe, a safe deposit box, as well as a private bathroom boasting a shower and a hairdryer. The air-conditioned twin/double room features a flat-screen TV, soundproof walls, a coffee machine, a seating area as well as mountain views.',
  },
  {
    name: 'Deluxe Room V',
    description:
      'Ideal for couples, these one-bedroom apartments offer a harmonious balance of comfort and sophistication. Enjoy your balcony view.',
    image: deluxe5,
    secondaryText:
      'The twin/double room provides air conditioning, soundproof walls, a balcony with mountain views as well as a private bathroom featuring a shower',
  },
  {
    name: 'Deluxe Room VI',
    description:
      'Designed with couples and small families in mind, Deluxe Room VI is a one-bedroom apartment that effortlessly strikes the right chord between comfort and sophistication.',
    image: deluxe6,
    secondaryText:
      'The twin/double room offers a wardrobe, a safe deposit box, as well as a private bathroom boasting a shower and a hairdryer. The spacious air-conditioned twin/double room features a flat-screen TV, soundproof walls, a coffee machine, a seating area as well as mountain views.',
  },
]

export const GALLERY = [
  'https://i.ibb.co/XD8Kkh4/2.jpg',
  'https://i.ibb.co/Scb2SVY/1.jpg',
  'https://i.ibb.co/WkZLfJh/3.jpg',
  'https://i.ibb.co/rG1hwS4/4.jpg',
  'https://i.ibb.co/gtvTj4P/5.jpg',
  'https://i.ibb.co/PTpzDhs/6.jpg',
  'https://i.ibb.co/PYqJWLK/7.jpg',
  'https://i.ibb.co/BKhYFj2/8.jpg',
  'https://i.ibb.co/mXVbvYq/9.jpg',
  'https://i.ibb.co/xCcc2x2/10.jpg',
  'https://i.ibb.co/TLK8nnp/11.jpg',
  'https://i.ibb.co/DbTM230/12.jpg',
  'https://i.ibb.co/4jwxsG5/13.jpg',
  'https://i.ibb.co/tb0F89R/15.jpg',
  'https://i.ibb.co/tZ5QPfW/14.jpg',
  'https://i.ibb.co/tCx0Xd7/16.jpg',
  'https://i.ibb.co/VBR7L50/17.jpg',
  'https://i.ibb.co/RcYVBbW/18.jpg',
  'https://i.ibb.co/ck0KhL8/19.jpg',
  'https://i.ibb.co/Mh7NXRh/20.jpg',
  'https://i.ibb.co/4JwZSJP/21.jpg',
  'https://i.ibb.co/LxVj72b/22.jpg',
  'https://i.ibb.co/VCw4dVD/23.jpg',
  'https://i.ibb.co/8xHjPQz/25.jpg',
  'https://i.ibb.co/LpMjzyy/24.jpg',
  'https://i.ibb.co/rx34bfR/26.jpg',
  'https://i.ibb.co/qj3GSW3/27.jpg',
  'https://i.ibb.co/wpbjFzm/28.jpg',
  'https://i.ibb.co/nB5vnTp/29.jpg',
  'https://i.ibb.co/DgrrKXy/30.jpg',
]
