import location from '../assets/icons/location.svg'
import parking from '../assets/icons/parking.svg'
import hotel from '../assets/icons/hotel.svg'
import cityView from '../assets/icons/image-gallery 1.svg'
import wifi from '../assets/icons/wifi.svg'
import coffee from '../assets/icons/coffee.svg'

export const FACILITIES = [
  {
    title: 'Family rooms',
    description: 'Enjoy spacious and comfy family rooms for a perfect stay with your loved ones.',
    icon: location,
  },
  {
    title: 'Parking Space',
    description: 'Rest easy, we collaborated with a parking lot near our accomodation.',
    icon: parking,
  },
  {
    title: 'Safety & Security',
    description: 'Your safety and security are our top priorities, ensuring a worry-free stay.',
    icon: hotel,
  },
  {
    title: 'City View',
    description: 'Wake up to breathtaking city views from the comfort of your room.',
    icon: cityView,
  },
  {
    title: 'Internet',
    description: 'Stay connected with high-speed internet access throughout your stay.',
    icon: wifi,
  },
  {
    title: 'Breakfast',
    description:
      'Start your day right with a delicious Bosnian breakfast for 10€ in the restaurant just around the corner.',
    icon: coffee,
  },
]

export const BASE_URL = 'https://www.booking.com/hotel/ba/apartments-87-sarajevo4.en-gb.html'
export const DEFAULT_SEARCH =
  '&aid=356980&dest_id=-94996&dest_type=city&dist=0&do_availability_check=1&hp_avform=1&hp_group_set=0&origin=hp&sb_price_type=total&src=hotel&type=total#availability_target'
