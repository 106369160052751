/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'

import './Search.scss'
import 'react-calendar/dist/Calendar.css'

// Icons
import calendarIcon from '../../assets/icons/calendar.svg'
import guestsIcon from '../../assets/icons/guests.svg'
import keyIcon from '../../assets/icons/key-chain.svg'
import plusIcon from '../../assets/icons/plus.svg'
import minusIcon from '../../assets/icons/minus.svg'
import Calendar from 'react-calendar'
import Popup from 'reactjs-popup'
import { BASE_URL, DEFAULT_SEARCH } from '../../utils/consts'
import { formatDate } from '../../utils/date'
import { ROOMS } from '../../utils/data'

const initialSearch = {
  checkIn: new Date().toLocaleDateString(),
  checkOut: new Date().toLocaleDateString(),
  adults: 1,
  children: 0,
  rooms: 1,
}

const Counter = ({ onChange, initialCount, step, max_count }) => {
  const maxCount = max_count ? max_count : 9999
  const counterStep = step || 1
  const countStart = initialCount ? initialCount : 1
  const [count, setCount] = useState(countStart)
  const increaseCounter = () => {
    if (count < maxCount) {
      setCount(count + counterStep)
    }
  }
  const decreaseCounter = () => {
    if (count == countStart || count - counterStep < countStart) return
    setCount(count - counterStep)
  }
  useEffect(() => {
    onChange(count)
  }, [count])

  return (
    <div className="counter">
      <div className="counter-btn" onClick={decreaseCounter}>
        <img src={minusIcon} />
      </div>
      <span>{count}</span>
      <div className="counter-btn" onClick={increaseCounter}>
        <img src={plusIcon} />
      </div>
    </div>
  )
}

const generateAvailabilityURL = (searchParams) => {
  const url = new URL(BASE_URL)
  url.searchParams.append('checkin', formatDate(searchParams.checkIn))
  url.searchParams.append('checkout', formatDate(searchParams.checkOut))
  url.searchParams.append('group_adults', searchParams.adults)
  url.searchParams.append('group_children', searchParams.children)
  url.searchParams.append('no_rooms', searchParams.rooms)
  return `${url.toString()}${DEFAULT_SEARCH}`
}

function Search() {
  const [searchParams, setSearchParams] = useState(initialSearch)
  const [availabilityURL, setAvailabilityURL] = useState(null)
  useEffect(() => {
    setAvailabilityURL(generateAvailabilityURL(searchParams))
  }, [searchParams])
  if (searchParams == null) setSearchParams(initialSearch)
  const updateRoomNumber = (newNumber) => {
    setSearchParams((prevState) => ({
      ...prevState,
      rooms: newNumber,
    }))
  }

  const updateSearchParams = (key, value) => {
    if (String(key).startsWith('check') && value) {
      const newVal = value.toLocaleDateString()
      setSearchParams((prev) => ({ ...prev, [key]: newVal }))
      if (Date.parse(searchParams.checkIn) > Date.parse(searchParams.checkOut)) {
        setSearchParams((prev) => ({ ...prev, checkOut: prev.checkIn }))
      }
      return
    }
    setSearchParams((prev) => ({ ...prev, [key]: value }))
  }

  return (
    <div className="search">
      <Popup
        position={'top center'}
        trigger={
          <div className="searchBtn input">
            <div className="content">
              <img src={calendarIcon} />
              <p>Check-in</p>
              <p className="secondary_txt">{searchParams.checkIn}</p>
            </div>
          </div>
        }
        on={'click'}
      >
        <Calendar
          onChange={(e) => updateSearchParams('checkIn', e)}
          value={searchParams.checkIn}
          minDate={new Date()}
        />
      </Popup>
      <Popup
        position={'top center'}
        trigger={
          <div className="searchBtn input">
            <div className="content">
              <img src={calendarIcon} />
              <p>Check-out</p>
              <p className="secondary_txt">{searchParams.checkOut}</p>
            </div>
          </div>
        }
        on={'click'}
      >
        <Calendar
          onChange={(e) => updateSearchParams('checkOut', e)}
          value={searchParams.checkOut}
          minDate={new Date(searchParams.checkIn)}
        />
      </Popup>
      <Popup
        position={'top center'}
        trigger={
          <div className="searchBtn input">
            <div className="content">
              <img src={guestsIcon} />
              <p>Guests</p>
              <p className="secondary_txt">
                {searchParams.adults} Person{searchParams.adults > 1 && 's'}
              </p>
              {searchParams.children > 0 && (
                <p className="secondary_txt">
                  {searchParams.children} Child{searchParams.children > 1 && 'ren'}
                </p>
              )}
            </div>
          </div>
        }
        on={'click'}
      >
        <div className="guests-picker">
          <div className="counter-content">
            <span>Adults</span>
            <Counter onChange={(val) => updateSearchParams('adults', val)} initialCount={searchParams.adults} />
          </div>
          <hr />
          <div className="counter-content">
            <span>Children</span>
            <Counter onChange={(val) => updateSearchParams('children', val)} initialCount={0} />
          </div>
        </div>
      </Popup>

      <div className="searchBtn input">
        <div className="content">
          <img src={keyIcon} />
          <p>Rooms</p>
        </div>
        <Counter onChange={updateRoomNumber} max_count={ROOMS.length} />
      </div>
      <a href={availabilityURL} target="_blank" rel="noreferrer" className="searchBtn search-submit">
        <span className="submit_txt">
          CHECK
          <br />
          AVAILABILITY
        </span>
      </a>
    </div>
  )
}

export default Search
