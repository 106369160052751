import { useState } from 'react'
import Layout from '../../components/Layout'
import './About.scss'
import heroImage from '../../assets/about-us-desc.png'
import backBtn from '../../assets/icons/back-btn.svg'
import nextBtn from '../../assets/icons/next-btn.svg'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import Facility from '../../components/facility/Facility'
import { FACILITIES } from '../../utils/consts'
import expandIcon from '../../assets/icons/expand.svg'
import Button from '../../components/button/Button'
import rightFig from '../../assets/about-us-right.svg'
import { GALLERY, ROOMS } from '../../utils/data'

function AboutUs() {
  const [expanded, setExpanded] = useState(0)

  const handleExpandClick = (idx) => {
    if (idx == expanded) {
      setExpanded(null)
    }
    setExpanded(idx)
  }
  return (
    <Layout>
      <div className="hero">
        <h1 className="title">Your Luxury Haven in the Heart of Sarajevo</h1>
      </div>
      <section className="about-us-content">
        <img src={rightFig} className="right-fig" />
        <div className="content-container">
          <h2 className="title">The place 87</h2>
          <p className="description">
            {`Discover a world of comfort and elegance nestled in the heart of Sarajevo, where luxury meets convenience at
            The Place 87. As a cherished family-owned business, we take pride in offering you a haven of tranquility and
            opulence, just a stone's throw away from the iconic Cathedral of the Sacred Heart of Jesus`}
          </p>
        </div>
        <div className="inner-container">
          <div className="content-w-img">
            <div className="content-container">
              <h2 className="title">Luxurious Living Redefined</h2>
              <p className="description">
                {`
At The Place 87, we understand the essence of true luxury living. Our exquisite apartments have been meticulously designed to provide you with an unparalleled living experience, where every detail speaks of sophistication and comfort. With a prime location that effortlessly blends city vibrancy with serene surroundings, you'll find yourself amidst the cultural and historical tapestry of Sarajevo.`}
              </p>
            </div>
            <img src={heroImage} style={{ objectFit: 'cover' }} />
          </div>
          <div className="content-container">
            <h2 className="title">Your hosts, your family</h2>
            <p className="description">
              {`
When you choose The Place 87, you're not just a guest; you become part of our extended family. With a commitment to genuine hospitality, our attentive team is always at your service, ready to ensure your stay exceeds expectations.`}
            </p>
          </div>
        </div>
      </section>
      <Swiper
        className="gallery-swiper"
        slidesPerView={3}
        spaceBetween={20}
        breakpoints={{
          970: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          300: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        }}
      >
        {GALLERY.map((url, index) => (
          <SwiperSlide key={index}>
            <img className="gallery-img" src={url} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="facilities">
        <h1 className="title">Our facilities</h1>
        <p className="facilities-desc">
          <span>Beyond the sheer luxury of our apartments,</span>
          <br />
          <span>The Place 87 offers an array of amenities designed to enhance your stay.</span>
        </p>
        <div className="swiper-view">
          <Swiper
            modules={[Navigation]}
            slidesPerView="auto"
            centeredSlides={true}
            spaceBetween={20}
            className="facility-swiper"
            navigation={{
              prevEl: '.facility-prev',
              nextEl: '.facility-next',
            }}
          >
            {FACILITIES.map((facility, idx) => (
              <SwiperSlide key={idx} className="swiper-slide">
                <Facility title={facility.title} description={facility.description} icon={facility.icon} />
              </SwiperSlide>
            ))}
            <div className="facility-nav">
              <img src={backBtn} className="facility-prev" />
              <img src={nextBtn} className="facility-next" />
            </div>
          </Swiper>
        </div>
        <div className="facility-list">
          {FACILITIES.map((facility, idx) => (
            <Facility key={idx} title={facility.title} description={facility.description} icon={facility.icon} />
          ))}
        </div>
      </div>
      <section className="our-rooms-about-us">
        <h2 className="section-title">Unveiling Our Apartment Collection</h2>
        <p className="section-desc">
          {`Step into a realm of choice as we present our exclusive collection of six distinct apartment types, each
          catering to diverse tastes and preferences. Whether you're a solo traveler seeking a cozy abode or a family
          yearning for expansive spaces, we have the perfect sanctuary waiting for you.`}
        </p>
        <div style={{ width: '90%', margin: '0 auto' }}>
          <Swiper
            modules={[Navigation]}
            className="rooms-swiper"
            navigation={{
              prevEl: '.facility-prev',
              nextEl: '.facility-next',
            }}
            breakpoints={{
              1200: {
                slidesPerView: 3,
                spaceBetween: 20,
                centeredSlides: false,
              },
              1000: {
                slidesPerView: 2.5,
                spaceBetween: 20,
                centeredSlides: false,
              },
              800: {
                slidesPerView: 2,
                spaceBetween: 20,
                centeredSlides: false,
              },
              600: {
                slidesPerView: 1.5,
                centeredSlides: true,
              },
              0: {
                slidesPerView: 1,
                centeredSlides: true,
              },
            }}
          >
            {ROOMS.map((room, idx) => (
              <SwiperSlide key={idx} className="room-slide">
                <div className="room-card">
                  <img src={room.image} className="room-img" />
                  <div>
                    <h2 className="room-name">{room.name}</h2>
                  </div>
                  {expanded === idx ? (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <p className="room-details">{room.description}</p>
                      <p className="room-details-secondary">{room.secondaryText}</p>
                      <div className="book-btn">
                        <Button text={'Book now'} />
                      </div>
                    </div>
                  ) : null}
                  {expanded !== idx ? (
                    <img style={{ marginTop: 15 }} src={expandIcon} onClick={() => handleExpandClick(idx)} />
                  ) : null}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </Layout>
  )
}

export default AboutUs
