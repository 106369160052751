import { useState } from 'react'
import './App.scss'
import heroImage from './assets/hero.png'
import showcaseImg from './assets/showcase.png'
import Footer from './components/footer/Footer'
import Button from './components/button/Button'
import Facility from './components/facility/Facility'
import figureRight from './assets/figure-right.svg'
import figureLeft from './assets/figure-left.svg'
import figureLeftMobile from './assets/figure-left-mobile.svg'
import expandIcon from './assets/icons/expand.svg'
import stars from './assets/icons/5star.svg'
import reviewImg from './assets/review.png'

import backBtn from './assets/icons/back-btn.svg'
import nextBtn from './assets/icons/next-btn.svg'

//libs
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import 'swiper/scss'
import { useRef } from 'react'
import { FACILITIES } from './utils/consts'
import Header from './components/header/Header'
import Search from './components/search/Search'
import { CUSTOMER_REVIEWS, ROOMS } from './utils/data'

function App() {
  const [expanded, setExpanded] = useState(0)

  const handleExpandClick = (idx) => {
    if (idx == expanded) {
      setExpanded(null)
    }
    setExpanded(idx)
  }
  const footerRef = useRef(null)
  const scrollToBottom = () => {
    footerRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  return (
    <div className="App">
      <Header scrollToBottom={scrollToBottom} />
      <main>
        <div className="hero">
          <img src={heroImage} />
          <Search />
        </div>
        <section className="about-section">
          <img src={figureLeft} className="figure fig-left" />
          <img src={figureRight} className="figure fig-right" />
          <div className="showcase">
            <img src={figureLeftMobile} className="fig-left-mobile" />
            <img src={showcaseImg} className="showcaseImg" alt="Showcase" />
            <div className="content">
              <h2>Your Luxury Haven in the Heart of Sarajevo</h2>
              <p className="description">
                Discover a world of comfort and elegance nestled in the heart of Sarajevo, where luxury meets
                convenience at The Place 87.
              </p>
              <div className="btn">
                <Button text={'read more'} link={'/about-us'} />
              </div>
            </div>
          </div>
          <div className="mobile read-more">
            <Button text={'read more'} link={'/about-us'} />
          </div>
          <div className="facilities">
            <h1 className="section-title">Our facilities</h1>
            <div className="swiper-view">
              <Swiper
                modules={[Navigation]}
                slidesPerView="auto"
                centeredSlides={true}
                spaceBetween={20}
                className="facility-swiper"
                navigation={{
                  prevEl: '.facility-prev',
                  nextEl: '.facility-next',
                }}
              >
                {FACILITIES.map((facility, idx) => (
                  <SwiperSlide key={idx} className="swiper-slide">
                    <Facility title={facility.title} description={facility.description} icon={facility.icon} />
                  </SwiperSlide>
                ))}
                <div className="facility-nav">
                  <img src={backBtn} className="facility-prev" />
                  <img src={nextBtn} className="facility-next" />
                </div>
              </Swiper>
            </div>
            <div className="facility-list">
              {FACILITIES.map((facility, idx) => (
                <Facility key={idx} title={facility.title} description={facility.description} icon={facility.icon} />
              ))}
            </div>
          </div>
        </section>
        <section className="our-rooms">
          <h2 className="section-title">Our rooms</h2>
          <div style={{ width: '90%', margin: '0 auto' }}>
            <Swiper
              modules={[Navigation]}
              className="rooms-swiper"
              breakpoints={{
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                  centeredSlides: false,
                },
                1000: {
                  slidesPerView: 2.5,
                  spaceBetween: 20,
                  centeredSlides: false,
                },
                800: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                  centeredSlides: false,
                },
                600: {
                  slidesPerView: 1.5,
                  centeredSlides: true,
                },
                0: {
                  slidesPerView: 1,
                  centeredSlides: true,
                },
              }}
            >
              {ROOMS.map((room, idx) => (
                <SwiperSlide key={idx} className="room-slide">
                  <div className="room-card">
                    <img src={room.image} className="room-img" />
                    <div>
                      <h2 className="room-name">{room.name}</h2>
                    </div>
                    {expanded === idx ? (
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <p className="room-details">{room.description}</p>
                        <p className="room-details-secondary">{room.secondaryText}</p>
                      </div>
                    ) : null}
                    {expanded !== idx ? (
                      <img style={{ marginTop: 15 }} src={expandIcon} onClick={() => handleExpandClick(idx)} />
                    ) : null}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
        <section className="satisfied-clients">
          <h2 className="clients-title">Satisfied clients</h2>
          <div className="clients-swiper">
            <Swiper
              modules={[Navigation]}
              breakpoints={{
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                  centeredSlides: false,
                },
                1000: {
                  slidesPerView: 2.5,
                  spaceBetween: 20,
                  centeredSlides: false,
                },
                800: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                  centeredSlides: false,
                },
                600: {
                  slidesPerView: 1.5,
                  centeredSlides: true,
                },
                0: {
                  slidesPerView: 1,
                  centeredSlides: true,
                },
              }}
              className="rooms-swiper"
              navigation={{
                prevEl: '.facility-prev',
                nextEl: '.facility-next',
              }}
            >
              {CUSTOMER_REVIEWS.map((customer, idx) => (
                <SwiperSlide key={idx}>
                  <div className="review-card">
                    <img src={reviewImg} className="reviewer-img" />
                    <h3 className="name">{customer.name}</h3>
                    <p className="text">{customer.comment}</p>
                    <img src={stars} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
      </main>
      <div ref={footerRef} />
      <Footer />
    </div>
  )
}

export default App
